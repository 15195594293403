import { Grid } from '@achieve/sunbeam'
import { Typography } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import styles from './StatGrid.module.scss'

function HairlineDivider() {
  return <hr className={styles['divider']} />
}

function StatGrid({ grid }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  grid = grid?.gridItems
  for (let i = 0; i < 8; i++) {
    if (!grid[i]) return null
  }

  let gridBox = []

  for (let i = 0; i < grid.length; i++) {
    if (grid[i].fields.fieldName === 'title') {
      gridBox.push(
        <Grid>
          <Typography
            variant="ascendLabelMd"
            fontWeight="regular"
            content={grid[i].fields.textContent}
            data-testid="video-title"
          />
          <Typography
            className={styles['blue-text']}
            variant={'displayS10'}
            content={grid[i + 1].fields.textContent}
            fontWeight="medium"
            data-testid="video-stat"
          />
        </Grid>
      )
    }
  }

  return (
    <>
      {isMobile ? (
        <Grid container className={styles['stat-grid-container']}>
          <Grid container className={styles['stat-grid-sub-grid']}>
            <Grid container item className={styles['stat-grid-element']}>
              <Grid>{gridBox[0]}</Grid>
              <Grid>{gridBox[1]}</Grid>
            </Grid>
            <HairlineDivider />
            <Grid container item className={styles['stat-grid-element']}>
              <Grid>{gridBox[2]}</Grid>
              <Grid>{gridBox[3]}</Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={styles['subtitle']}>
          <HairlineDivider />
          <Grid container className={styles['stat-grid-element']}>
            <Grid>{gridBox[0]}</Grid>
            <Grid>{gridBox[1]}</Grid>
            <Grid>{gridBox[2]}</Grid>
            <Grid>{gridBox[3]}</Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export { StatGrid }
